import Pricing from 'components/Pricing';
import { getActiveProductsWithPrices } from 'utils/supabase-client';
import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';

export default function PricingModal({ openModal, passOpenModal, translator }) {
  const [open, setOpen] = useState(true);

  return (
    <Transition.Root show={openModal} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={passOpenModal}>
        {/* <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        > */}
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        {/* </Transition.Child> */}

        <div className="fixed pt-16 inset-0 z-100 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              // as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden mx-2 my-5 rounded-lg bg-white px-2 pt-5 pb-3 text-left shadow-xl transition-all sm:my-8 sm:mx-8 sm:w-full  sm:p-6">
                <span
                  onClick={() => {
                    passOpenModal(false);
                  }}
                  className="absolute p-3 top-0 mb-1 font-bold text-indigo-400 cursor-pointer"
                >
                  X
                </span>
                <div className="px-2 sm:px-4 sm:px-4 lg:px-8">
                  <h1 className="text-2xl font-extrabold text-slate-900 text-center sm:text-4xl">
                    {translator('pricing_plans')}
                  </h1>
                  <p className="mt-5 text-lg text-slate-500 text-center sm:text-xl max-w-2xl m-auto">
                    {translator('pricing_plans_desc')}
                  </p>
                  <Pricing openModal={passOpenModal} translator={translator} />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
