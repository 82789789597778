import { createContext, useState, useContext } from 'react';

const RenderContext = createContext();

export const useRender = () => useContext(RenderContext);

export const RenderProvider = ({ children }) => {
  const [completedJobs, setCompletedJobs] = useState(0);
  const [hasMoreRenders, setHasMoreRenders] = useState(true);

  // Add more render-related states and functions as needed

  return (
    <RenderContext.Provider
      value={{
        completedJobs,
        setCompletedJobs,
        hasMoreRenders,
        setHasMoreRenders
      }}
    >
      {children}
    </RenderContext.Provider>
  );
};
