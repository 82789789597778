// createContext.ts
import { createContext } from "react";
import { modelInputProps } from "../helpers/Interfaces";

interface contextProps {
  clicks: [
    clicks: modelInputProps[] | null,
    setClicks: (e: modelInputProps[] | null) => void
  ];
  image: [
    image: HTMLImageElement | null,
    setImage: (e: HTMLImageElement | null) => void
  ];
  maskImg: [
    maskImg: HTMLImageElement | null,
    setMaskImg: (e: HTMLImageElement | null) => void
  ];
  maskLockedImgs: [
    maskLockedImgs: (HTMLImageElement | null)[],
    setMaskLockedImgs: (e: (HTMLImageElement | null)[]) => void
  ];
  maskLockedCanvasImgs: [
    maskLockedCanvasImgs: (HTMLCanvasElement | null)[],
    setMaskLockedCanvasImgs: (e: (HTMLCanvasElement | null)[]) => void
  ];
  brushMode: [
    brushMode: string,
    setBrushMode: (e: string) => void
  ];
}

const AppContext = createContext<contextProps | null>(null);

export default AppContext;