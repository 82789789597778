import Image from 'next/image';





// const Logo = ({ className = '', ...props }) => (

  
//   <span className='font-bold text-[#4138C2] p-4 rounded-lg flex-nowrap whitespace-nowrap  md:text-lg ' >SofaBrain 
//   <Image className="inline" width={30} src="/lowpolybrain copy.png" alt="SofaBrain Logo" height={30}  /></span>
// );


const Logo = ({ className = '', ...props }) => (

  <svg width="115" height="25" viewBox="0 0 115 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 23.5V17.3911C12 17.1252 11.892 16.8701 11.6998 16.6821L7.72472 12.7936C7.32447 12.4021 6.67553 12.4021 6.27528 12.7936L2.30019 16.6821C2.10798 16.8701 2 17.1252 2 17.3911V21.624C2 22.1777 2.45887 22.6265 3.02491 22.6265H5.70489C6.27093 22.6265 6.7298 22.1777 6.7298 21.624V19.2227M11.5238 22.5H18.1905C20.2944 22.5 22 20.7944 22 18.6905V6.30952C22 4.20558 20.2944 2.5 18.1905 2.5H5.80952C3.70558 2.5 2 4.20558 2 6.30952V10" stroke="url(#paint0_linear_4820_114)" strokeWidth="2"/>
<path d="M34.3408 15.131C34.3408 16.2126 34.9542 17.0198 36.2941 17.0198C37.3112 17.0198 37.9246 16.5193 37.9246 15.8574C37.9246 13.5489 32.3229 15.2117 32.3229 11.1274C32.3229 9.36782 33.9533 8.04407 36.1327 8.04407C38.3605 8.04407 40.0232 9.5454 40.0232 11.3373H37.6017C37.6017 10.74 36.9722 10.1427 36.1327 10.1427C35.2932 10.1427 34.6637 10.5624 34.6637 11.1274C34.6637 13.1454 40.3461 11.9023 40.3461 15.5992C40.3461 17.6171 38.5381 19.1184 36.1327 19.1184C33.7758 19.1184 32 17.4072 32 15.131H34.3408Z" fill="#292929"/>
<path d="M46.6524 19.1023C43.6174 19.1023 41.1475 16.6323 41.1475 13.5974C41.1475 10.5786 43.6174 8.12478 46.6524 8.12478C49.6873 8.12478 52.1572 10.5786 52.1572 13.5974C52.1572 16.6323 49.6873 19.1023 46.6524 19.1023ZM43.569 13.5974C43.569 15.5346 44.9734 17.0036 46.6524 17.0036C48.3313 17.0036 49.7357 15.5346 49.7357 13.5974C49.7357 11.6763 48.3313 10.2234 46.6524 10.2234C44.9734 10.2234 43.569 11.6763 43.569 13.5974Z" fill="#292929"/>
<path d="M53.4517 10.4817C53.4517 7.73734 55.3566 5.897 58.1979 5.88086V7.97949C56.7934 7.97949 55.8248 8.94809 55.7925 10.401H57.7943V12.4996H55.7925V18.7955H53.4517V10.4817Z" fill="#292929"/>
<path d="M69.668 18.7955H67.3272V17.7624C66.859 18.3435 65.4868 19.1023 64.0178 19.1023C61.3057 19.1023 58.8358 16.6323 58.8358 13.5974C58.8358 10.5786 61.3057 8.12478 64.3407 8.12478C67.3756 8.12478 69.668 10.5786 69.668 13.5974V18.7955ZM61.2573 13.5974C61.2573 15.5346 62.6618 17.0036 64.3407 17.0036C66.0196 17.0036 67.424 15.5346 67.424 13.5974C67.424 11.6763 66.0196 10.2234 64.3407 10.2234C62.6618 10.2234 61.2573 11.6763 61.2573 13.5974Z" fill="#292929"/>
<path d="M71.2819 5.88086H73.6227V9.46468C74.0909 8.88352 75.4631 8.12478 76.9321 8.12478C79.6442 8.12478 82.1141 10.5947 82.1141 13.6297C82.1141 16.6485 79.6442 19.1023 76.6092 19.1023C73.5743 19.1023 71.2819 16.6485 71.2819 13.6297V5.88086ZM73.5259 13.6297C73.5259 15.5507 74.9303 17.0036 76.6092 17.0036C78.2881 17.0036 79.6926 15.5507 79.6926 13.6297C79.6926 11.6925 78.2881 10.2234 76.6092 10.2234C74.9303 10.2234 73.5259 11.6925 73.5259 13.6297Z" fill="#292929"/>
<path d="M83.4052 12.6611C83.4052 9.9167 85.3101 8.07635 88.1513 8.06021V10.1588C86.7146 10.1588 85.746 11.1597 85.746 12.6611V18.7955H83.4052V12.6611Z" fill="#292929"/>
<path d="M99.6214 18.7955H97.2807V17.7624C96.8125 18.3435 95.4403 19.1023 93.9713 19.1023C91.2592 19.1023 88.7893 16.6323 88.7893 13.5974C88.7893 10.5786 91.2592 8.12478 94.2941 8.12478C97.3291 8.12478 99.6214 10.5786 99.6214 13.5974V18.7955ZM91.2108 13.5974C91.2108 15.5346 92.6152 17.0036 94.2941 17.0036C95.973 17.0036 97.3775 15.5346 97.3775 13.5974C97.3775 11.6763 95.973 10.2234 94.2941 10.2234C92.6152 10.2234 91.2108 11.6763 91.2108 13.5974Z" fill="#292929"/>
<path d="M101.397 8.30236H103.738V18.7955H101.397V8.30236Z" fill="#292929"/>
<path d="M112.659 12.6611C112.659 11.1597 111.691 10.1588 110.254 10.1588C108.817 10.1588 107.848 11.1597 107.848 12.6611V18.7955H105.508V12.6611C105.508 9.9167 107.413 8.07635 110.254 8.06021C113.095 8.07635 115 9.9167 115 12.6611V18.7955H112.659V12.6611Z" fill="#292929"/>
<defs>
<linearGradient id="paint0_linear_4820_114" x1="2.1129" y1="14.75" x2="22.9919" y2="14.75" gradientUnits="userSpaceOnUse">
<stop stopColor="#3864FF"/>
<stop offset="1" stopColor="#F1148B"/>
</linearGradient>
</defs>
</svg>
  
  );

export default Logo;
