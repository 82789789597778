import Head from 'next/head';
import { useRouter } from 'next/router';
import Navbar from 'components/ui/Navbar';
import Footer from 'components/ui/Footer';
import { ReactNode } from 'react';
import { PageMeta } from '../types';

interface Props {
  children: ReactNode;
  meta?: PageMeta;
}

// ignore ts
// @ts-ignore
export default function Layout({ children, meta: pageMeta,translator }: Props) {
  const router = useRouter();
  
  
  const meta = {
    title: 'AI Interior Design App| Virtual Home Staging Software | Best for Interior Designers and Real Estate Agents',
    description: 'SofaBrain is changing the way you think about interior design. Artificial intelligence lets you visualize a new living room, kitchen and more with our AI powered Interior Design app and staging simulator.',
    cardImage: 'https://imagedelivery.net/_KeVhvb2O4eJIzSpK4wEFQ/7d8a687b-e135-43b5-0f8e-a22db6be6b00/free',
    ...pageMeta
  };



  return (
    <div>
      <Head>
       
        <meta name="robots" content="follow, index" />
        <meta name="google" content="notranslate" />
        <meta name="thumbnail" content={meta.cardImage} />
        <link href="/favicon.ico" rel="shortcut icon" />
        <meta content={meta.description} name="description" key="main_description" />
        <title key="main_title">{meta.title}</title>
        <meta property="og:url" content={'https://sofabrain.com'} key="main_url"/>
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={meta.title} key="main_sitename_og"/>
        <meta property="og:description" content={meta.description} key="main_description_og"/>
        <meta property="og:title" content={meta.title} key="main_title_og"/>
        <meta property="og:image" content={meta.cardImage} key="main_og"/>
        <meta name="twitter:card" content={meta.description} key="twitter_card"/>
        <meta name="twitter:site" content="@sofabrainai" />
        <meta name="twitter:title" content={meta.title} key="twitter_title"/>
        <meta name="twitter:description" content={meta.description} key="twitter_description"/>
        <meta name="twitter:image" content={meta.cardImage} key="main_twitter" />
        
        <style>
          @import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
        </style>
        

       
      
      </Head>
      {router.asPath!== '/pro' &&
        <Navbar translator={translator} />
      }
      
      <main id="skip">{children}</main>
      {router.asPath!== '/pro' &&
        <Footer />
      }      
    </div>
  );
}


