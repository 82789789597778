import { createContext, useState, useContext } from 'react';

const FeedContext = createContext();

export const useFeed = () => useContext(FeedContext);

export const FeedProvider = ({ children }) => {
  const [publicFeed, setPublicFeed] = useState([]);
  const [privateFeed, setPrivateFeed] = useState([]);
  const [activeProjectFilter, setActiveProjectFilter] = useState(null);
  const [feedList, setFeedList] = useState([]);
  const [feedStyle, setFeedStyle] = useState('private');

  // Add more feed-related states and functions as needed

  return (
    <FeedContext.Provider
      value={{
        publicFeed,
        setPublicFeed,
        privateFeed,
        setPrivateFeed,
        activeProjectFilter,
        setActiveProjectFilter,
        feedList,
        setFeedList,
        feedStyle,
        setFeedStyle
      }}
    >
      {children}
    </FeedContext.Provider>
  );
};
