import { createContext, useState, useContext } from 'react';

const ImageContext = createContext();

export const useImage = () => useContext(ImageContext);

export const ImageProvider = ({ children }) => {
  const [file, setFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  // Add more image-related states and functions as needed

  return (
    <ImageContext.Provider value={{ file, setFile, imageUrl, setImageUrl }}>
      {children}
    </ImageContext.Provider>
  );
};
