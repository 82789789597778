import { createContext, useState, useContext } from 'react';

const ProjectContext = createContext();

export const useProject = () => useContext(ProjectContext);

export const ProjectProvider = ({ children }) => {
  const [availableProjects, setAvailableProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);

  // Add more project-related states and functions as needed

  return (
    <ProjectContext.Provider
      value={{
        availableProjects,
        setAvailableProjects,
        selectedProject,
        setSelectedProject
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};
