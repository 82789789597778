import { createContext, useState, useContext } from 'react';
import { useUser } from 'utils/useUser';

const UserContext = createContext();

export const useUserCon = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const { user, isLoading, userDetails, subscription } = useUser();

  // Add more user-related states and functions as needed

  return (
    <UserContext.Provider
      value={{
        user,
        subscription,
        userDetails
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
