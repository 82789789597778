import { Price } from 'types';

export const evaluateFileType = (
  file: File,
  jobcallback: Function,
  loadingcallback: Function
  // optional callback to handle success
) => {
  const fileType = file.type;

  const SUPPORTED_FILE_TYPES = [
    'image/jpg',
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/webp',
    'image/bmp',
    'image/tiff',
    'image/svg+xml',
    'image/avif',
    'image/heif',
    'image/heic'

    // 'image/gif'
  ];

  if (!SUPPORTED_FILE_TYPES.includes(file.type)) {
    jobcallback && jobcallback('Please select a valid image file');
    alert('Please select a valid image file jpg, jpeg or png');
    loadingcallback && loadingcallback('blank');
    throw Error('Please select a valid image file');
  }
};

export const getURL = () => {
  let url =
    process?.env?.NEXT_PUBLIC_SITE_URL ?? // Set this to your site URL in production env.
    process?.env?.NEXT_PUBLIC_VERCEL_URL ?? // Automatically set by Vercel.
    'http://localhost:3000/';
  // Make sure to include `https://` when not localhost.
  url = url.includes('http') ? url : `https://${url}`;
  // Make sure to including trailing `/`.
  url = url.charAt(url.length - 1) === '/' ? url : `${url}/`;
  return url;
};

export const getData = async (url: string) => {
  const res: Response = await fetch(url, {
    method: 'GET',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    credentials: 'same-origin'
  });

  if (!res.ok) {
    console.log('Error in getData', { url, res });

    throw Error(res.statusText);
  }

  return res.json();
};

export const postData = async ({
  url,
  data
}: {
  url: string;
  data?: { price: Price };
}) => {
  const res: Response = await fetch(url, {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    credentials: 'same-origin',
    body: JSON.stringify(data)
  });

  if (!res.ok) {
    console.log('Error in postData', { url, data, res });

    throw Error(res.statusText);
  }

  return res.json();
};

export const postDataAll = async ({
  url,
  data
}: {
  url: string;
  data?: { name: string; description: string };
}) => {
  const res: Response = await fetch(url, {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    credentials: 'same-origin',
    body: JSON.stringify(data)
  });

  if (!res.ok) {
    console.log('Error in postData', { url, data, res });

    throw Error(res.statusText);
  }

  return res.json();
};

export const toDateTime = (secs: number) => {
  var t = new Date('1970-01-01T00:30:00Z'); // Unix epoch start.
  t.setSeconds(secs);
  return t;
};
